<template>
  <div class="dropdown" v-if="options.length != 0">
    <div class="options " href="#" role="button" data-toggle="dropdown" aria-expanded="false">
      <ui-accion-button style="float: right; background: gray;">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="white" d="M5 23.7q-.825 0-1.413-.588Q3 22.525 3 21.7v-14q0-.825.587-1.413Q4.175 5.7 5 5.7h8.925L7 12.625V19.7h7.075L21 12.75v8.95q0 .825-.587 1.412q-.588.588-1.413.588Zm4-6v-4.25l7.175-7.175l4.275 4.2l-7.2 7.225Zm12.875-8.65L17.6 4.85l1.075-1.075q.6-.6 1.438-.6q.837 0 1.412.6l1.4 1.425q.575.575.575 1.4T22.925 8Z"/></svg>
      </ui-accion-button>
    </div>
    <div class="dropdown-menu" >
      <a class="dropdown-item" data-dismiss="modal" v-show="JSON.parse(option.permits).some(item =>userPermissions.includes(item))" v-for="(option, index) in  options" v-on:click="selectItemsTable( option.routine, option.callType.toLowerCase()) " :key="index" href="#">{{ option.label }}  </a>
    </div>
  </div>
</template>
<script>
//Tools
import Vuex from 'vuex'
import { API, Storage } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid';  
import { formatData, loading, popUp, calendar, actionCalendar, processRecordsToDisplay,recordToDisplay, orderArray } from '../../store/tools.js';
//Components
import PaperDisplayer from '../papers/PaperDisplayer.vue'
import UiDropdown from '../ui/UiDropdown.vue'
import UiAccionButton from '../ui/UiAccionButton.vue'
//AWS
import { smd_backendCustomQuery } from '../../graphql/queries'
import { smd_backendCustomMutation } from '../../graphql/mutations'

export default {
  name: 'ui-modal',
  components:{
    PaperDisplayer,
    UiDropdown,
    UiAccionButton
  },
  props:{ 
    options:{
      type: Array,  
      default: () => []
    },
    data:{
      type: String,
      default: 'data'
    },

  },
  data() {
    return {
      color:'#ccc',
    }
  },
  created() {
  },
  methods: {
    ...Vuex.mapMutations(['setResponseActionTable']),
    async selectItemsTable(event, action, item ){
      let query = null
      let pushData = null
      let pullData = null
      console.log(event, action, item);
      switch (action) {
        case 'query':
          try {
            pushData = await API.graphql({
              query: smd_backendCustomQuery,
              variables: { 
                items: JSON.stringify(this.data), operation: event
              },
            }); 
            pullData = pushData.data.smd_backendCustomQuery
            pullData = JSON.parse(pullData.data)
            console.log('>> Push query Respomse', pullData);
            this.setResponseActionTable(pullData)
            if (pullData.blank == true) {
              let url = this.$router.resolve({ name:'TableResponse',paht:'/tableresponse'}); 
              console.log(url)
              //window.open(url.href, '_blank')
            }
          } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
          }
        break;
        case 'mutation':
          try {
            pushData = await API.graphql({
              query: smd_backendCustomMutation,
              variables: { 
                items: JSON.stringify(this.data), operation: event
              },
            }); 
            pullData = pushData.data.smd_backendCustomMutation
            console.log('>> Push mutation Respomse', pullData);
            pullData = JSON.parse(pullData.data)
            this.setResponseActionTable(pullData)
            if (pullData.blank == true) {
              let url = this.$router.resolve({ name:'TableResponse',paht:'/tableresponse'}); 
              console.log(url)
              //window.open(url.href, '_blank')
            }
          } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
          }
        break;
      }
    },
  },
  computed: {
    ...Vuex.mapState(["organizationData","organizationID", 'record','targetFile','userPermissions' ]),
  },
}
</script>
