<template>
   <div class="smart-wrap" style="border: dashed 1px #ccc;">
      {{ SML.path }}
      <div class="container-web" :class="'container-web-'+index+'-'+data.SK" :id="'container-web-'+index" :style="'width:'+ display+'; margin:20px auto;'" v-for="(display, index) in displayWebSite" :key="index">
         <paper-layout v-if="SML.templateType == 'hf'" style="display: none;" :id="displayWebSiteId[index]" :idioms="SML.idioms" :language="'en'" :layout="SML.layout" :models="{...SML.binds, responsive: index}" :palette="SML.palette" :paperPermissions="{usersCanEdit:[], usersCanRead:[],groupsCanEdit:[],groupsCanRead:[]}"/>
         <paper-layout v-else :idioms="SML.idioms" :language="'en'" :layout="SML.layout" :models="{responsive: index, ...SML.binds }" :palette="SML.palette" :paperPermissions="{usersCanEdit:[], usersCanRead:[],groupsCanEdit:[],groupsCanRead:[]}"/>
      </div>
   </div>
</template>
<script>
//AWS
import { smd_getWebPage, smd_generateWebSite } from '../../graphql/queries'
import { } from '../../graphql/mutations'
import { loading, popUp } from '../../store/tools.js'

//Tools
import Vuex from 'vuex'
import { API, Storage, Auth } from 'aws-amplify'
import { v4 as uuidv4 } from 'uuid';
//Components
import PaperLayout from '../papers/PaperLayout.vue'
import PaperDisplayer from '../papers/PaperDisplayer.vue'
export default {
   name: 'paper-render-sml',
   components: {
      PaperLayout,
      PaperDisplayer,
   },
   data() {
      return {
         SML: {},
         HTMLWebSite: '',
         displayWebSite: ['100%', '992px', '767px', '450px',],
         displayWebSiteId: ['displayDesk', 'displayLaptop' , 'displayTablet','displayPhone'],
      }
   },
   props: {
      data: {
         type: Object,
         default: {
            SK: '',
            PK: '',
         }
      }
   },
   created() {
      this.renderSML()
   },
   methods: {
      ...Vuex.mapMutations([]),
      ...Vuex.mapActions([]),
      //Get Web Page
      async renderSML() {
         loading('show', 'webPreview')
         let pushData = {};
         let pullData = ''
         try {
            pushData = {
               orgPK: this.data.PK,
               SMLPK: this.data.PK,
               SMLSK: this.data.SK
            }
            //console.log('pushData Get Data SML:', pushData);
            pullData = await API.graphql({
               query: smd_getWebPage,
               variables: pushData
            });
            pullData = pullData.data.smd_getWebPage
            pullData = JSON.parse(pullData.data)
            //Process att of SML record ()
            for (let index = 0; index < JSON.parse(pullData.SML).length; index++) {
               const element = JSON.parse(pullData.SML)[index];
               try {
                  if (element.attributeType == 'L') {
                     element.attributeValue = JSON.parse(element.attributeValue)
                   }
               } catch (error) {
                  //console.log(error);
               }
               pullData[element.attributeName] = element.attributeValue
            }
            //console.log('Web Site Data:', pullData);
            pullData.binds.form ={}
            this.SML = pullData
            setTimeout(() => {
               this.upSML()
            }, 5000);
            //Render and catch of Web
         } catch (error) {
            console.log(error);
         }
         //loading('hide', 'webPreview')
      },
      async upSML() {
         loading('show', 'webPreview')
         let pushData = {};
         let pullData = ''
         try {
            
            pushData ={
               itemPK: this.data.PK,
               itemSK: this.data.SK,
               destination: this.SML.path,
               template: this.SML.template,
               HTML0: document.getElementsByClassName('container-web-0-'+this.data.SK)[0].innerHTML,
               HTML1: '',//adocument.getElementsByClassName('container-web-1-'+this.data.SK)[0].innerHTML,
               HTML2: '',//document.getElementsByClassName('container-web-2-'+this.data.SK)[0].innerHTML,
               HTML3: document.getElementsByClassName('container-web-3-'+this.data.SK)[0].innerHTML,
               CSSStyles:this.SML.class,
               fonts: JSON.stringify(this.SML.fonts),
               palette: JSON.stringify(this.SML.palette)
            }
            console.log('pushData HTML of SML:'+ this.SML.path, pushData);
            pullData = await API.graphql({
               query: smd_generateWebSite,
               variables: pushData
            });
            pullData = pullData.data.smd_generateWebSite
            console.log(document.getElementsByClassName('container-web-0-'+this.data.SK)[0].innerHTML.length);
            console.log('Response of Upload HTML of SML:'+ this.SML.path, pullData);
            //this.$emit('show', 'design')

            //Render and catch of Web
         } catch (error) {
            popUp(error, 'response', 'err')
            console.log(error);
         }
         loading('hide', 'webPreview')
      },
   },
   computed: {
      ...Vuex.mapState(['organization'])
   },
}
</script>