<template>
  <div class="dropdown">
    <div class="options " href="#" role="button" data-toggle="dropdown" aria-expanded="false">
      <slot name="option" >

      </slot>
    </div>
    <div class="dropdown-menu">
      <slot name="option" >

      </slot>
    </div>
  </div>
</template>
<script>
//Tools
import Vuex from 'vuex'
import { formatData, loading } from '../../store/tools.js';
import { v4 as uuidv4 } from 'uuid';  
//Components
import PaperDisplayer from '../papers/PaperDisplayer.vue'

export default {
  name: 'ui-modal',
  components:{
    PaperDisplayer,
  },
  props:{
    type:{
      type: String,
      default: 'type'
    },
    name:{
      type: String,
      default: 'name'
    },
    classModal:{
      type: String,
      default: ''
    },
    data:{
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      color:'#ccc',
    }
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...Vuex.mapState(["organizationData","organizationID", 'record','targetFile' ]),
  },
}
</script>
