<template>
  <!--Modal DLV-->
  <div class="paper-dash" v-if="organizationData.app == 'DLV' && gRecord">
    <ui-modal name="dlv-customer" type="update" classModal="modal-dialog-centered modalAll" v-if="gRecord[2] == 'customer'">
      <template #content  v-if="record == null" >
      </template>
      <template #content v-else>
        <div class="smart-wrap modal-header" style="margin: 0 0 20px 0;">
          <div class="smart-wrap " style="display: flex; justify-content: space-between;">
            <h1>Order</h1>
            <ui-button color="green" text="Save" v-on:click="attUpdate(record.item)"/>  
            <ui-button data-dismiss="modal" text="X"/>  
          </div>
        </div>
        <img v-if="record.item.attributes.driver" style=" background: #ccc; border-radius: 300px; width: 70px; height: 70px; margin: 0 10px 0 0;" :src="record.item.attributes.driver.picture" alt="">
        <h4>{{ record.item.attributes.driver.attributeValue.value }}</h4>
        <div class="smart-wrap">
          <div class="input-wrap col-md-6" v-for="(input, x) in record.item.attributes" :key="x">
            <label for="">{{ input.label }}</label>
            <span class="" v-if="input.attributeType == 'L'" >{{ input.attributeValue.value }}</span>
            <span class="" v-else >{{input.attributeValue }}</span>
          </div>
          <!--Notes-->
          <div class="input-wrap col-md-6">
            <label for="">Notes</label>
            <textarea name="" id="" cols="30" rows="10" v-model="record.item.attributes.notes.attributeValue"></textarea>
          </div>
          
          <!--Products-->
          <div class="list-group col-md-6">
            <h6>Products</h6>
            <div class="list-group-item list-group-item-action" v-for="(row, index) in record.item.attributes.products.attributeValue" :key="index">
              <div v-for="(item, key) in row" :key="key">
                <strong>{{key}}</strong> {{ item }} 
              </div>
            </div>
            <div class="list-group-item list-group-item-action">
              Add
            </div>
          </div>
          <!--Pictures-->
          <div class="list-group col-md-6">
            <h6>Pictures</h6>
            <div class="list-group-item list-group-item-action" v-for="(row, index) in record.item.attributes.pictures.attributeValue" :key="index">
              <img style="width:100%;" :src="row.url" :alt="row.url">
              <div v-show="key == 'name' || key == 'security' || key == 'createdAt' || key == 'description'" v-for="(item, key) in row" :key="key">
                <strong>{{key}}</strong> {{ item }} 
              </div>
            </div>
            <div class="list-group-item list-group-item-action" data-toggle="modal" data-target="#modal-file">
              Add
            </div>
          </div>
        </div>
      </template>
    </ui-modal>
    <ui-modal name="dlv-driver" type="update" classModal="modal-dialog-centered modalAll" v-if="gRecord[2] == 'driver'" >
      <template #content  v-if="record == null" >
      </template>
      <template #content v-else>
        <div class="smart-wrap modal-header" style="margin: 0 0 20px 0;">
          <div class="smart-wrap " style="display: flex; justify-content: space-between;">
            <h1>Delivery</h1>
            <ui-button color="green" text="Save" v-on:click="attUpdate(record.item)"/>  
            <ui-button data-dismiss="modal" text="X"/>  
          </div>
        </div>
        <img v-if="record.item.attributes.driver" style=" background: #ccc; border-radius: 300px; width: 70px; height: 70px; margin: 0 10px 0 0;" :src="record.item.attributes.driver.picture" alt="">
        <h4 v-if="record.item.attributes.driver">{{ record.item.attributes.driver.attributeValue.value }}</h4>
        <div class="smart-wrap">
          <div class="input-wrap col-md-6 spaceBetween">
            <label for="">{{ record.item.attributes.orderNumber.label }}</label>
            
            <span class="" >{{ record.item.attributes.orderNumber.attributeValue }}</span>
          </div>
          <!--Notes-->
          <div class="input-wrap col-md-6">
            <label for="">Status</label>
            <select name="" id="">
              <option value="">Delivered</option>
              <option value="">Cancelled</option>
            </select>
          </div>
          <!--Notes-->
          <div class="input-wrap col-md-6">
            <label for="">Notes</label>
            <textarea name="" id="" cols="30" rows="10" v-model="record.item.attributes.notes.attributeValue"></textarea>
          </div>
          <!--Pictures-->
          <div class="list-group col-md-6">
            <h6>Pictures</h6>
            <div class="list-group-item list-group-item-action" v-for="(row, index) in record.item.attributes.pictures.attributeValue" :key="index">
              <img style="width:100%;" :src="row.url" :alt="row.url">
              <div v-show="key == 'name' || key == 'security' || key == 'createdAt' || key == 'description'" v-for="(item, key) in row" :key="key">
                <strong>{{key}}</strong> {{ item }} 
              </div>
            </div>
            <div class="list-group-item list-group-item-action" data-toggle="modal" data-target="#modal-file">
              Add
            </div>
          </div>
        </div>
      </template>
    </ui-modal>
    <ui-modal name="dlv-pharmacy" type="update" classModal="modal-dialog-centered modalAll" v-if="gRecord[2] == 'pharmacy'">
      <template #content  v-if="record == null" >
      </template>
      <template #content v-else>
        <div class="smart-wrap modal-header" style="margin: 0 0 20px 0;">
          <div class="smart-wrap " style="display: flex; justify-content: space-between;">
            <h1>Driver</h1>
            <ui-button color="green" text="Save" v-on:click="attUpdate(record.item)"/>  
            <ui-button data-dismiss="modal" text="X"/>  
          </div>
        </div>
        <img v-if="record.item.attributes.driver" style=" background: #ccc; border-radius: 300px; width: 70px; height: 70px; margin: 0 10px 0 0;" :src="record.item.attributes.driver.picture" alt="">
        <h4 v-if=" record.item.attributes.driver">{{ record.item.attributes.driver.attributeValue.value }}</h4>
        <div class="smart-wrap">
          <div class="input-wrap col-md-6" v-for="(input, x) in record.item.attributes" :key="x">
            <label for="">{{ input.label }}</label>
            <span class="" v-if="input.attributeType == 'L'" >{{ input.attributeValue.value }}</span>
            <span class="" v-else >{{input.attributeValue }}</span>
          </div>
          <!--Notes-->
          <div class="input-wrap col-md-6">
            <label for="">Notes</label>
            <textarea name="" id="" cols="30" rows="10" v-model="record.item.attributes.notes.attributeValue"></textarea>
          </div>
          <!--Products-->
          <div class="list-group col-md-6">
            <h6>Products</h6>
            <div class="list-group-item list-group-item-action smart-wrap" style="padding: 5px;" v-for="(row, index) in record.item.attributes.products.attributeValue" :key="index">
              <div class="input-wrap col-10">
                <label for="">Product Description</label>
                <input type="text" name="" id="" v-model="record.item.attributes.products.attributeValue[index].product">
              </div>
              <div class="input-wrap col-2">
                <label for="">Qty</label>
                <input type="number" name="" id="" v-model="record.item.attributes.products.attributeValue[index].qty">
              </div>
              <div class="input-wrap col-2">
                <label for="">Price</label>
                <input type="number" name="" id="" v-model="record.item.attributes.products.attributeValue[index].price">
              </div>
            </div>
          </div>
          <!--Pictures-->
          <div class="list-group col-md-6">
            <h6>Pictures</h6>
            <div class="list-group-item list-group-item-action" v-for="(row, index) in record.item.attributes.pictures.attributeValue" :key="index">
              <img style="width:100%;" :src="row.url" :alt="row.url">
              <div v-show="key == 'name' || key == 'security' || key == 'createdAt' || key == 'description'" v-for="(item, key) in row" :key="key">
                <strong>{{key}}</strong> {{ item }} 
              </div>
            </div>
            <div class="list-group-item list-group-item-action" data-toggle="modal" data-target="#modal-file">
              Add
            </div>
          </div>
        </div>
      </template>
    </ui-modal>
    <ui-modal :name="'dlv-new'" type="update" classModal="modal-dialog-centered modalAll" v-if="gRecord[2] == 'new-pharmacy'" >
      <template #content v-if="record">
        <div class="smart-wrap modal-header" style="margin: 0 0 20px 0;">
          <div class="smart-wrap " style="display: flex; justify-content: space-between;">
            <h1>Delivery</h1>
            <ui-button data-dismiss="modal" color="green" text="Save" v-on:click="updateRecord(record,'update');"/>  
            <ui-button data-dismiss="modal" text="X"/>  
          </div>
        </div>
        <div class="smart-wrap">
          <div class="input-wrap col-md-6 ">
            <label for="">{{ record.item.attributes.orderNumber.label }}</label>
            <input type="text" v-model="record.item.attributes.orderNumber.attributeValue">
          </div>
          <!--Products-->
          <div class="input-wrap col-md-6 ">
            <label for="">{{ record.item.attributes.notes.label }}</label>
            <textarea name="" id="" cols="30" rows="5" v-model="record.item.attributes.notes.attributeValue"></textarea>
          </div>
          <!--Notes-->
        </div>
      </template>
    </ui-modal>
    <ui-modal :name="'dlv-new'" type="update" classModal="modal-dialog-centered modalAll" v-if="gRecord[2] == 'new-client'" >
      <template #content v-if="record">
        <div class="smart-wrap modal-header" style="margin: 0 0 20px 0;">
          <div class="smart-wrap " style="display: flex; justify-content: space-between;">
            <h1>Delivery</h1>
            <ui-button data-dismiss="modal" color="green" text="Save" v-on:click="updateRecord(record,'update');"/>  
            <ui-button data-dismiss="modal" text="X"/>  
          </div>
        </div>
        <div class="smart-wrap">
          <!--Products-->
          <div class="list-group col-md-12">
            <h6>Products</h6>
            <div class="list-group-item list-group-item-action smart-wrap" style="padding: 5px;" v-for="(row, index) in record.item.attributes.products.attributeValue" :key="index">
              <div class="input-wrap col-10">
                <label for="">Product Description</label>
                <input type="text" name="" id="" v-model="record.item.attributes.products.attributeValue[index].product">
              </div>
              <div class="input-wrap col-2">
                <label for="">Qty</label>
                <input type="number" name="" id="" v-model="record.item.attributes.products.attributeValue[index].qty">
              </div>
            </div>
            
              <div class="list-group-item list-group-item-action" v-on:click="record.item.attributes.products.attributeValue.push({ })">
                Add
              </div>
              <div class="input-wrap col-md-12 " >
                <label for="">{{ record.item.attributes.notes.label }}</label>
                <textarea name="" id="" cols="30" rows="5" v-model="record.item.attributes.notes.attributeValue"></textarea>
              </div>
             <!--Notes-->
          </div>
          
         
        </div>
      </template>
    </ui-modal>
  </div>
  <!--Modal File-->
  <ui-modal :name="'file'" type="file" >
    <template  #content>
      <div class="wrap-head">
        <div class="row">
          <div class="col wrap-title">
            <h4 class="title-g">
              
            </h4>
          </div>
          <div class="col wrap-button">
            <ui-button :color="'gray'" :text="'X'" :position="'right'" id="close-inner-modal" v-on:click="closeModal('modal-file')" />
          </div>
        </div>
      </div>
      <div class="smart-wrap">
        <div class="input-wrap col-md-12">
          <label >Description</label>
          <textarea name="" id="descriptionFile" cols="10" rows="4" v-model="descriptionFile"></textarea>
        </div>
        <div class="input-wrap col-md-12">
          <label for="">File</label>
          <input v-on:change="fileUp" type="file" multiple name="" :id="index" ref="refFile" style="padding: 6px;" >
        </div>
      </div>
    </template>
  </ui-modal>
  <ui-modal :name="'notes'" type="file" v-if="record != null">
    <template  #content>
      <div class="wrap-head">
        <div class="row">
          <div class="col wrap-title">
            <h4 class="title-g">
              
            </h4>
          </div>
          <div class="col wrap-button">
            <ui-button :color="'gray'" :text="'X'" :position="'right'" data-dismiss="modal" style="margin: 0 0 0 10px;"/>
            <ui-button :color="'gray'" :text="'Save'" :position="'right'" data-dismiss="modal" v-on:click="attUpdate(record.item)"/>
          </div>
        </div>
      </div>
      <div class="smart-wrap">
        <div class="input-wrap col-md-12">
          <label >Notes</label>
          <textarea name="" id="descriptionFile" cols="10" rows="4" v-model="record.item.attributes.notes.attributeValue"></textarea>
        </div>
      </div>
    </template>
  </ui-modal>
</template>
<script>
//AWS - GRAPHQL
import {  smd_getRecord, smd_getDashDataYear,smd_getDashDataContact, smd_generateGraphicPost, smd_getSMLItem,smd_listEntityObj, smd_composeEmailTemplate } from '../../graphql/queries'
import { smd_updateSmartRecord,smd_setItemState, smd_sendEmail2,smd_sendSMS, smd_updateAttributes } from '../../graphql/mutations'
import { onUpdateSmartRecord } from '../../graphql/subscriptions'
//Tools
import Vuex from 'vuex'
import { API, Storage } from 'aws-amplify'
import Swal from 'sweetalert2'
import { formatData, loading, popUp, searchList, processLayout } from '../../store/tools.js';
import { v4 as uuidv4 } from 'uuid';
//Components
import PaperLayout from './PaperLayout.vue'
import PaperDisplayer from './PaperDisplayer.vue'
import UiButton from '../ui/UiButton.vue'
import UiModal from '../ui/UiModal.vue'
//import $ from "jquery";
//import html2pdf from 'html2pdf'
export default {
  name: "paper-modal",
  components:{
    UiButton,
    PaperLayout,
    PaperDisplayer,
    UiModal,
  },
  data() {
    return {
      dataSMS: null,
      data: null, 
      layout:[],

      //DATA
      record: null,
      recordB: null,
      contact: null,
      subData: null,
      //render
      render: false,
      //file
      descriptionFile:'',
    }
  },
  created() {
    
  },
  methods: {
    //Vuex
    ...Vuex.mapMutations(["setDataUser","setRecord","setgRecord", "setRecordSub",'setNotifications']),
    ...Vuex.mapActions(["setFormat","getRecord","cloneRecod","deleteRecord"]),
    styleModal(modal){
      console.log(Math.floor($('#modal-update').scrollTop()));
      document.getElementById(modal).style.paddingTop = Math.floor($('#modal-update').scrollTop())+'px'
      console.log(document.getElementById(modal).style.paddingTop);
    },
    actionSave(){
      if (this.$router.currentRoute.value.query.name == 'clietDash') {
        let forceUpdate = document.getElementById('forceUpdate')
        console.log(forceUpdate);
        var event = new Event('click');
        forceUpdate.dispatchEvent(event);
      }
    },
    //Sub
    //Tools Debugin
    formatData(format, data){
      return formatData(format, data)
    },
    //Record handling
    async getItem( data, mode, actionItem, second  ) { 
      this.actionModal('subscription', 'close')
      console.log('getItem');
      if (actionItem && actionItem == 'stay') {
        console.log([data, mode, actionItem, second],'\n only cache---------');
        return
      }
      //console.clear()
      let pullData = null
      let action = null
      let entity = null
      //Web Edit 
      let groupRecord = undefined
      //console.log('>> Data Item to Update:\n '+mode,data);
      //console.log('>> Mode: ',mode);
      try {
        let query = {
          itemPK: data.PK,
          itemSK: data.SK,
          SMLPK: data.form.PK,
          SMLSK: data.form.SK,
          parent: data.parent,
          entity: data.entity
        }
        switch (mode) {
          case 'out':
            console.log(data);
            this.record = {}
            this.record.item = data
            loading('hidde', 'getItem || set out')
            return
          break;
          case 'status':
            console.log(data);
            this.record = {}
            this.record.item = data
            this.record.title = actionItem + ' item '+ data.entityName + " - " + data.friendlyId
            this.record.action = actionItem
            $('#modal-status').modal('show')
            loading('hidde', 'getItem || set out')
            return
          break;
          case 'update':
          $('#modal-update').modal('show')
          break;
          case 'new-dlv':
            try {   
              console.log('in new-dlv');
              console.log(this.entities[data.shortEntity]);
              for (let index = 0; index < JSON.parse(this.entities[data.shortEntity]['SML.descendantEntitiesWithForm']).length; index++) {
                const entity = JSON.parse(this.entities[data.shortEntity]['SML.descendantEntitiesWithForm'])[index];
                if (entity.app == 'DLV') {
                  action = entity
                }
              }
              console.log('This action for item:',action);
              query.SMLPK = action['modules.formSK'].PK
              query.SMLSK = action['modules.formSK'].SK
              query.entity = {
                PK: action['metaEntity.descendantEntities'].PK ,
                SK: action['metaEntity.descendantEntities'].SK 
              },
              query.parent = {
                PK: data.PK ,
                SK: data.SK,
              }
              query.itemPK= undefined
              query.itemSK= undefined
              //Check entity for record
              for (const key in this.entities) {
                if ( action['metaEntity.descendantEntities'].value == this.entities[key]['metaEntity.name']) {
                  entity = this.entities[key]
                }
              }
            } catch (error) {
              console.log(error);
            }
          break;
          case 'action':
            return
          break;
        } 
        console.log('>> Query to Update;',query);
        pullData = await API.graphql({
          query: smd_getSMLItem,
          variables: query
        });
        
        pullData = pullData.data.smd_getSMLItem
        //Response
        if (pullData.smd_response.statusCode != 200) {
          popUp(pullData.smd_response, 'response', 'err')
          loading('hidde', 'getItem')
          return
        }
        else if (pullData.smd_response.popAlert == true) {
          popUp(pullData.smd_response, 'response', 'scc')
        }
        //Proccess Data
        pullData.layout =  JSON.parse(pullData.data)
        
        pullData.item =  JSON.parse(pullData.item)
        if (mode == 'new-dlv') {
          console.log(entity);
          pullData.item.shortEntity = entity['metaEntity.metaShortEntity']
          pullData.item.entity = query.entity
          pullData.item.entityName = entity['metaEntity.name']
          pullData.item.parent = {
            PK: data.PK,
            SK: data.SK,
          }
          pullData.item.form = {
            PK:  query.SMLPK,
            SK:  query.SMLSK,
          }
          if (this.gRecord[3]) {
            pullData.item.attributes.provider.attributeValue = {
              PK:this.attUser['custom:organizationID'],
              SK: this.attUser['custom:responsibleSK'],
              value:this.attUser.name
            }
            console.log(this.gRecord[3]);
            let provider =  this.gRecord[3]
            console.log(provider)
            pullData.item.attributes['provider.state'].attributeValue = provider.attributes['provider.state'].attributeValue
            pullData.item.attributes['provider.streetAddress'].attributeValue = provider.attributes['provider.streetAddress'].attributeValue
            pullData.item.attributes['provider.zipCode'].attributeValue = provider.attributes['provider.zipCode'].attributeValue
            pullData.item.attributes['provider.city'].attributeValue = provider.attributes['provider.city'].attributeValue

            pullData.item.attributes['provider.phoneNumber'].attributeValue = provider.attributes['provider.phoneNumber'].attributeValue
          }

          pullData.item.attributes.date.attributeValue = new Date().toJSON()
          pullData.item.attributes.status.attributeValue = 'Ready to be picked up'
        }
        pullData.attStr =  JSON.stringify(pullData.item.attributes)
        
        delete pullData.data;
        if (!pullData.item.PK) {
          pullData.item.PK = this.organizationID
        } 
        if (mode == 'new') {
          if (data.SK != "ADD"){
            pullData.item.PK = this.organizationID
            pullData.item.entity = {
              PK: data['modules.mainEntity'].PK,
              SK: data['modules.mainEntity'].SK,
            };
            pullData.item.form = {
              PK: data['modules.formSK'].PK,
              SK: data['modules.formSK'].SK,
            };
            pullData.item.shortEntity = data['modules.dataQuery'];
          }else{
            pullData.item.entity = data.entity
            pullData.item.form = data.form
            pullData.item.shortEntity = data.shortEntity
            if (groupRecord) {
              pullData.item.attributes.group.attributeValue = groupRecord
            }
          }
          pullData.item.usersCanEdit = [];
          pullData.item.usersCanRead = [ ];
          pullData.item.groupsCanEdit = [ "Admin", "Editor","Superadmin"].concat(this.userPermissions);
          pullData.item.groupsCanRead = [ "Admin", "Editor","Superadmin"].concat(this.userPermissions);
          pullData.item.groupsCanEdit = pullData.item.groupsCanEdit.concat(this.moduleLayout.groupCanEdit);
          pullData.item.groupsCanRead = pullData.item.groupsCanRead.concat(this.moduleLayout.groupCanRead);
        }
        if (pullData.layoutCategory = "smartForm") {
          pullData.layout = processLayout(pullData.layout,'setOrder')
        }
        if( pullData.item.attributes['responsible'] && pullData.item.attributes['responsible'].attributeValue && (pullData.item.attributes['responsible'].attributeValue.id == this.user)){
          pullData.layout = processLayout(pullData.layout,'checkResponsible', this.user)
        } 
        //if (this.organizationID == 'ORG#SMARTDASH') { // ORG#SMARTDASH DIFERENT #SMARTDASH (SYSTEM)
        //   console.log(this.organizationID)
        //    pullData.item.PK = '#SMARTDASH'
        //  }
        console.log("-->> Record to Update: ", pullData);
        if (second == true) {
          this.recordB = pullData
        }else{
          this.record = pullData
        }
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      this.$forceUpdate();
      loading('hidde', 'getItem')
      //this.setgRecord(null)
    },

    async updateRecord(data, mode, second) {  
      //console.clear();
      loading('show', 'updateRecord')
      //console.log('>> Data Record to '+ mode+': ',data);
      let query = { }
      let pushData = ''
      let response = ''
      switch (mode) {
        case 'update':
          try {
            //Set Mode and Config
            //asconsole.log(JSON.stringify(processBinds('push',data.item.attributes )));
            query = {
              shortEntity: data.item.shortEntity,
              PK: data.item.PK,
              SK: data.item.SK,
              attributesStr: JSON.stringify(data.item.attributes) ,
              entity: data.item.entity,
              form: data.item.form,
              parent:  data.item.parent,
              usersCanEdit: data.item.usersCanEdit,
              usersCanRead: data.item.usersCanRead ,
              groupsCanEdit: data.item.groupsCanEdit,
              groupsCanRead: data.item.groupsCanRead,
            }
            if (query.SK == '') {
              query.SK = undefined
            }
            console.log(' >> Push Data <<', query);
            //Mutation
            pushData = await API.graphql({
              query: smd_updateSmartRecord, //smd_updateSmartRecord
              variables: { 
                input:query
              },
            });
            console.log(' >> Response update <<' ,pushData);
            pushData = pushData.data.smd_updateSmartRecord
            response = pushData
            pushData = JSON.parse(pushData.data)
            this.record.item = pushData
            if (!second) {
              this.setgRecord([pushData, 'update', 'stay'])
            }
          } catch (error) {
            console.log(error);
            popUp(error, 'cach', 'err')
          }
        
        break;
        case 'Delete':
          query = {
            PK: data.item.PK,
            SK: data.item.SK,
            state:'0',
          }
          console.log(query);
          pushData = await API.graphql({
            query: smd_setItemState,
            variables: query,
          });
          pushData = pushData.data.smd_setItemState
          response = pushData
          console.log(pushData);
          if (data.item.shortEntity == 'NOT') {
            this.setNotifications({action:'delete', value:data.item})
          }
          loading('hidde', 'getItem')
        break;
        case 'Active':
          query = {
            PK: data.item.PK,
            SK: data.item.SK,
            state:'1',
          }
          pushData = await API.graphql({
            query: smd_setItemState,
            variables: query,
          });
          pushData = pushData.data.smd_setItemState
          response = pushData
        break;
        case 'Archive':
          query = {
            PK: data.item.PK,
            SK: data.item.SK,
            state:'2',
          }
          pushData = await API.graphql({
            query: smd_setItemState,
            variables: query,
          });
          pushData = pushData.data.smd_setItemState
          response = pushData
        break;
      }
      //console.log(' >> Successful response <<' ,response);
      if (response.smd_response.statusCode != 200) {
        popUp(response.smd_response, 'response', 'err')
        loading('hidde', 'getItem')
        return
      }
      else if (response.smd_response.popAlert == true) {
        popUp(response.smd_response, 'response', 'scc')
      }
      if (location.pathname == "/webpreview") {
        location.reload();
      }
      loading('close');
    },
    async attUpdate(data){
      loading('show', 'getItem')
      let pullData = ''
      let pushData = ''
      let att = []
      att =[
        { 
            attributeName: 'notes',
            attributeType: "S",
            attributeValue: data.attributes.notes.attributeValue
        },
        { 
            attributeName: 'orderNumber',
            attributeType: "S",
            attributeValue: data.attributes.orderNumber.attributeValue
        },
        { 
            attributeName: 'pictures',
            attributeType: "L",
            attributeValue: data.attributes.pictures.attributeValue
        },
      ]
      pushData={
          PK: data.PK,
          SK: data.SK ,
          attributes: JSON.stringify(att) ,
      }
      console.log('pushData: ',pushData);
      try {
          pullData = await API.graphql({
            query: smd_updateAttributes, //smd_updateSmartRecord
            variables: { 
                PK: data.PK,
                SK: data.SK ,
                attributes: JSON.stringify(att) ,
            },
          }
      );
      console.log(pullData);
      } catch (error) {
          console.log(error);
          popUp(error, 'cach', 'err')
      }
      loading('hidde', 'getItem')
    }, 
    //Ui Functions
    actionModal(name, action){
      console.log(name, action);
      switch (action) {
        case 'close':
          $('#modal-'+name).modal('hide')
        break;
        case 'email':
          $('#modal-'+name).modal('hide')
          setTimeout(() => {
            $('#modal-send').modal('toggle')
            $('#modal-send').modal('show')
          }, 200);
        break;
      }
    },
    async fileUp(e){
      console.clear()
      loading('show');
      //File Config
      let event = e
      try {
        let name = event.target.value.replace('C:\\fakepath\\', '')
        let extension = name.split('.')[name.split('.').length-1]
        let data = {
          name: name,
          extension: extension,
          key: uuidv4()+"."+extension,
          securityFile: 'public',
          file: event.target.files[0],
          index: event.target.id
        }

        if (data.file) {
          let result = await Storage.put(
            this.organizationID+"/"+data.key,
            data.file,
            {
              contentDisposition: "inline",
              contentType: data.file.type,
              level: data.securityFile,
            }
          );
          let url = 'https://smartdashs3120108-dev.s3.amazonaws.com/public/'+ this.organizationID.replace('#','%23')+"/"+data.key
          //this.closeModal('modal-file')
          this.record.item.attributes.pictures.attributeValue.push({
            name: data.name,
            url: url,
            key: data.key, 
            securityFile: 'public',
            description: this.descriptionFile,
            identityId:this.identityId,
            route: data.securityFile+'/'+this.identityId+'/'+this.organizationID+"/"+ data.key,
            createdAt: new Date().toISOString(),
          })
        }
        this.attUpdate(this.record.item)
        this.descriptionFile = ''
        this.actionModal('file', 'close')
        //var save = new Event('click');
        //let buttonSave = document.getElementById('save-'+this.data.SK);
        //console.log(buttonSave);
        //buttonSave.dispatchEvent(save);
      } catch (error) {
        console.log(error);
        popUp(error, 'cach', 'err')
      }
      loading('close');
    },
  },
  computed: {
    ...Vuex.mapState(['pageQuery',"organizationID","userPermissions", "entity","organizationPalette", "pageMeta", "pageModule", "permissions", "user", 'recordView', "dataUser", 'dashData','recordLists','dataModule','organizationData','entities','modules','gRecord','bRecord','recordSub','menu','preloadLists','moduleLayout','identityId','attUser']),
  },
  watch: {
    gRecord:{
      handler(val) {
        if (val != null  ) {
          this.getItem(val[0], val[1], val[2])
        }
      },
      deep: true
    },
  },
}
</script>
<style lang="less">
  .currency-input{
    overflow: hidden;
    position: relative;
    input{
      padding: 10px 10px 10px 20px!important;
    }
    .symbol{
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      padding: 12px 0 0 10px;
      font-size: 14px;
    }
  }
</style>